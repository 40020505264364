<template>
    <div class="exposure">
        <el-card>
            <div slot="header" class="clearfix">
                <span>单边敞口</span>
                <date-range style="float: right" v-on:change="getData"></date-range>
            </div>
            <h-chart ref="chart" id="chart" :options="options"></h-chart>
            <div class="blackboard">
                <p class="star-level"><span>指标星级：</span>
                    <el-rate v-model="starLevel" disabled></el-rate>
                </p>
                <p>指标释义：计算公式为（多仓持仓金额-空仓持仓金额）/账户总金额。</p>
                <p>
                    如何使用：指标描述了该账户的风险暴露特性，该指标正的越大（或负的越大），说明账户的风险暴露很高，旨在通过高风险获取高报酬。该指标越趋近于0，说明账户的风险暴露越小，一般套利策略属于此类。</p>
            </div>
        </el-card>
    </div>
</template>

<script>
  import HChart from '@/components/HChart.vue'
  import DateRange from '@/components/DateRange.vue'
  import {Loading} from "element-ui";
  import {apiAnalysisData} from "../../api/analysis";

  export default {
    name: "exposure",
    data () {
      return {
        starLevel: 4,
        options: {
          credits: {
            enabled: false
          },
          chart: {
            backgroundColor: null,
            plotBackgroundColor: null
          },
          title: false,
          colors: ['#EB3E41'],
          plotOptions: {
            series: {
              marker: {
                enabled: false
              },
            }
          },
          xAxis: {
            // tickInterval: 20,
            categories: []
          },
          yAxis: {
            title: false,
          },
          series: [],
        }
      };
    },
    components: {
      HChart,
      DateRange
    },
    methods: {
      async getData () {
        if (this.$store.state.curAccountId.length === 0) {
          return false;
        }
        let loadingInstance = Loading.service({target: '.h-chart'});
        const params = {
          a_id: this.$store.state.curAccountId.join(','),
          type: this.$store.state.analysisType,
          b_date: this.$store.state.dateRange.b_date,
        e_date: this.$store.state.dateRange.e_date
        }
        let res = await apiAnalysisData('exposure', params)
        if (res.data.code === 0 && res.data.data) {
          const resData = JSON.parse(JSON.stringify(res.data.data))
          let categories = []
          let f1s = []
          Array.isArray(resData) && resData.map(item => {
            if (item.date) {
              categories.push(item.date)
            }
            if (item.f1 || item.f1 === 0) {
              f1s.push(item.f1)
            }
          })
          this.options.xAxis.categories = categories;
          this.options.series = [{
            name: '单边敞口',
            data: f1s
          }];
          this.$refs.chart.redraw();
          loadingInstance.close();
        }
      }
    },
    mounted () {
      this.getData();
    }
  }
</script>

<style lang="scss">
    .exposure {
        .el-card {
            .el-card__header {
                background: #1777FF;
                color: #ffffff;
            }

            .chart {
                margin-bottom: 20px;
                background-image: url("../../assets/watermark.png");
                background-repeat: no-repeat;
                background-size: 269px 150px;
                background-position: center;
            }
        }
    }
</style>
